import ItStep1 from "./step1/it.step1";
import ItStep2 from "./step2/it.step2";
import StepVideoQuizz from "../shared/step_video_quizz/StepVideoQuizz";
import ItStep4 from "./step4/it.step4";
import ItStep6 from "./step6/it.step6";
import ItStep8 from "./step8/it.step8";
import ItStep10 from "./step10/it.step10";
import ItStep11 from "./step11/it.step11";
import ItStep12 from "./step12/ItStep12";
import HintItStep2 from "./step2/hint/hint.it.step2";
import HintItStep4 from "./step4/hint/it.step.4";
import ItHint1Step6 from "./step6/hint/ItHint1Step6";
import ItHintStep8 from "./step8/hint/ItHintStep8";
import ItHintStep10 from "./step10/hint/ItHintStep10";

const sustainableItEnDecathlonScenario = {
    title: 'Sustainable IT - Decathlon',
    description: 'Discover best practices for a more responsible digital world.',
    longDescription: 'Learn more about responsible digital practices by solving puzzles as a team. Will you be the fastest and strongest team!?',
    lang: "en",
    rules: [
        "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/rules_decathlon.webm",
        "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/rules.webm"
    ],
    tablet: false,
    steps: [
        {
            component: <ItStep1/>,
            baseHints: [],
            description: {
                backgroundLight: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step1/background_light.webp",
                backgroundNoLight: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step1/background_nolight.webp",
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step1/video.webm",
                lock: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step1/lock.png",
                answer: "60",
                question: "By 2040, digital GHG emissions are expected to increase by ?"
            },
        },
        {
            component: <ItStep2/>,
            baseHints: [HintItStep2],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step2/background_p",
                sketch: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step2/sketch_p",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step2/hint_p",
                description: [
                    <p>Having access to the Internet allows us to call, <strong>chat</strong>, exchange and learn remotely via social networks, webinars, video conferences, forums, tutorials, Moocs...</p>,
                    <p>Nowadays, with our computers, tablets, phones etc., it is easy to check emails, write an article, do financial analysis. So everyone can (tele)<strong>work</strong> easily..</p>,
                    <p>Digital technology also makes it possible to <strong>entertain</strong> oneself online (reading, listening to music, watching a series, playing a console or scrolling through networks).</p>,
                    <p>Today, more than ever, organizations are relying on digital technologies to <strong>automate</strong> their processes. This improves efficiency, increases customer satisfaction, enhances security and reduces costs (neo-banks, Uber, Airbnb, ...).</p>
                ],
                answers: [
                    "chat",
                    "work",
                    "entertain",
                    "automate",
                ],
                title: "What's digital for?"
            }
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step3/video.webm",
                questions: [
                    {
                        name: "What is the “rebound effect”?",
                        answers: [
                            "A - The “rebound effect” is an economic phenomenon that occurs when a recession is followed by a period of strong growth.",
                            "B - The rebound effect is a physical phenomenon that occurs when an object bounces off a surface after being thrown.",
                            "C - The rebound effect is a term used in psychology to describe the behavior of certain individuals who recover quickly after a trauma or injury.",
                            "D - The rebound effect is when we save energy or resources, but increase our overall consumption, thereby negating the environmental benefits of our efforts."
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "The “rebound effect” refers to the fact of saving energy or resources while increasing overall consumption, thus canceling out the environmental benefits of the approach.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What percentage of the total data flow on the Internet do video contents represent?",
                        answers: [
                            "A - 10%",
                            "B - 40%",
                            "C - 80%",
                            "D - 90%"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "The correct answer was 80%.",
                        multiple: false,
                        bonus: 30
                    }
                ]
            }
        },
        {
            component: <ItStep4/>,
            baseHints: [HintItStep4],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step4/background_p",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step4/hint_p",
                backgroundPassword: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step4/background_password.webp",
                imageCompleted: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step4/answer_p",
                answers: [
                    ["infrastructures"],
                    ["manufacturing"],
                    ["waste"],
                    ["devices"],
                    ["obsolescence"],
                ],
                description: [
                    "Often invisible because they are in the ocean or in space, yet they are essential to the network's operation.",
                    "It is by far the main source of the digital impact on the environment.",
                    "In 2021, they already weighed 57 million tons.",
                    "There are 34 billion of them in the world.",
                    "It can be technical or psychological..."
                ],
                descriptionCompleted: [
                    "For our devices to have Internet, we need satellites, undersea cables, relay antennas, data centers, etc., which we may not necessarily see.",
                    "All these digital devices need to be designed properly before we can use them. This step is the biggest source of the digital impact.",
                    "Our old devices are buried, incinerated, or illegally exported because some people recover the metals in our devices. Thus, recycling is only partial.",
                    "Phones, computers, smartwatches, video game consoles... in short, all our connected objects arrive in our daily lives and are ready to use. But what lies behind our clicks?",
                    "It can be technical or psychological (marketing) and pushes us to buy new products to have the latest model... even though our devices still work..."
                ],
                prefix: [
                    "",
                    "The",
                    "",
                    "Our",
                    "",
                ]
            }
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step5/video.webm",
                questions: [
                    {
                        name: "What is the main source of the digital impact?",
                        answers: [
                            "A - Manufacturing digital equipment",
                            "B - Electricity consumption of data centers",
                            "C - The usage phase of our equipment (sports with a smartwatch, videos on our computers, games on video game consoles, etc.)",
                            "D - Networks (satellites, undersea cables, etc.)"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "Manufacturing digital equipment is by far the most important source of digital impact.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What percentage of the carbon footprint is attributed to the manufacturing phase in digital?",
                        answers: [
                            "A - 24%",
                            "B - 46%",
                            "C - 78%",
                            "D - 99%"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "The correct answer was 78%.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "How many devices were counted in the digital world in 2019?",
                        answers: [
                            "A - 4.1 billion",
                            "B - 8 billion",
                            "C - 32 billion",
                            "D - 34 billion"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "In 2019, there were over 34 billion digital devices, and this number continues to grow.",
                        multiple: false,
                        bonus: 30
                    }
                ]
            }
        },
        {
            component: <ItStep6/>,
            baseHints: [ItHint1Step6],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step6/background_p",
                hint: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step6/hint_p",
                backpack: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step6/backpack.webp",
                answers: [
                    "200",
                    "600",
                    "70",
                    "50",
                ],
                description1: <>When I carry my <strong>2kg laptop</strong> in my bag, there are also:</>,
                answer1: "fossil fuels",
                answer2: "minerals",
                description2: "and thousands of liters of freshwater.",
                description3: <>When I carry <strong>my smartphone</strong> in my bag, there are also:</>,
                answer3: "different materials, including",
                answer4: "metals",
                description4: "4 world tours are required to make it (design, extraction, assembly, distribution...)."
            }
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step7/video.webm",
                questions: [
                    {
                        name: "How many kg of resources are needed to manufacture a 2kg computer?",
                        answers: [
                            "A - 800kg",
                            "B - 600kg",
                            "C - 70kg",
                            "D - 50kg"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "The correct answer was 800kg.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What percentage of rare earths (critical metals) is recycled?",
                        answers: [
                            "A - 1%",
                            "B - 17%",
                            "C - 37%",
                            "D - 60%"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "Only 1% of rare earths are currently recycled.",
                        multiple: false,
                        bonus: 30
                    }
                ]
            }
        },
        {
            component: <ItStep8/>,
            baseHints: [ItHintStep8],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step8/background_p",
                hints: [
                    <>Air, water & land are subject to this phenomenon. Indeed, they are constantly contaminated by extraction, refining, and waste production. It is called <span className={"font-bold text-red-500"}>?</span></>,
                    <>Without certain key resources to power and produce new digital equipment, data centers, network infrastructures... there will be a discontinuity of digital services. These non-renewable critical resources will be lacking, leading to <span className={"font-bold text-red-500"}>?</span></>,
                    <>The more resources are extracted, the rarer and more valuable they become in exporting countries. Price surges and geopolitical tensions are to be expected. Each importing or exporting country wants to find its own interest. This could lead to <span className={"font-bold text-red-500"}>?</span></>,
                    <>Invisible but so useful, I am indispensable in thousands of liters to produce your digital devices. Moreover, in some areas, there are more demands than available quantities, and I generate water stress.</>,
                    <>Digital represents 4% of global greenhouse gas emissions. Equivalent to the aviation sector. Due to rising temperatures, we are facing a disruption of the system. It's the <span className={"font-bold text-red-500"}>?</span> climate!</>,
                    <>Mental or physical <span className={"font-bold text-red-500"}>?</span> is impacted by digital. Without realizing it, it takes a toll. By never disconnecting, every human is subjected to tensions and stress that affect their overall state.</>,
                    <>Fauna & flora are also impacted by all human activities. Indeed, greenhouse gases, water contamination, land contribute to <span className={"font-bold text-red-500"}>?</span> biodiversity.</>,
                    <>To manufacture equipment, fossil and mineral energy resources are needed. However, these resources are non-renewable and rare. Beware of <span className={"font-bold text-red-500"}>?</span>.</>
                ],
                crossword: {
                    across: {
                        2: { clue: '', answer: 'INTERRUPTION', row: 7, col: 0 },
                        5: { clue: '', answer: 'WARMING', row: 15, col: 2 },
                        6: { clue: '', answer: 'HEALTH', row: 12, col: 3 },},
                    down: {
                        1: { clue: '', answer: 'POLLUTION', row: 7, col: 7 },
                        3: { clue: '', answer: 'CONFLICT', row: 2, col: 0 },
                        4: { clue: '', answer: 'WATER', row: 3, col: 5 },
                        7: { clue: '', answer: 'DESTRUCTION', row: 0, col: 2 },
                        8: { clue: '', answer: 'SHORTAGE', row: 5, col: 10 },
                    },
                }
            }
        },
        {
            component: <StepVideoQuizz/>,
            baseHints: [],
            description: {
                video: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step9/video.webm",
                questions: [
                    {
                        name: "What are blood minerals?",
                        answers: [
                            "A - They are the concentration levels of minerals found in the blood of miners",
                            "B - They are the wounds that a miner gets during the extraction of rare and precious metals",
                            "C - They are minerals called so because they are used in the medical field.",
                            "D - They are minerals also called conflict minerals and extracted in areas of human rights violations where profits usually fund armed groups."
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "Blood minerals get their name from the conflicts that their extraction generates. Often extracted in areas of human rights violations, and profits fund armed groups.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Among these impacts caused by digital, which one is the odd one out?",
                        answers: [
                            "A - Mental Health",
                            "B - Water Stress",
                            "C - Destruction of biodiversity",
                            "D - Pollution of water, soil, and air"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "Mental health is not an environmental impact!",
                        multiple: false,
                        bonus: 30
                    }
                ]
            }
        },
        {
            component: <ItStep10/>,
            baseHints: [ItHintStep10],
            description: {
                background: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step10/background_p",
                hintAll: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/fr/step10-decathlon/hint_all.webp",
                hint1: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step10-decathlon/hint1_p",
                hint2: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step10-decathlon/hint2_p",
                hint3: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step10-decathlon/hint3_p",
                hint4: "https://api.thewokies.fr/storage/v1/object/public/scenario/sustainable-it/v1/en/step10-decathlon/hint4_p",
                answers:  [
                    "12",
                    "60",
                    "89",
                    "19",
                    "38",
                    "2",
                    "46",
                    "49",
                    "94",
                    "28",
                    "35",
                    "66",
                    "33",
                    "67",
                    "54",
                    "27",
                ],
                descriptions: [
                    "Decathlon includes a measurable planet KPI in its job descriptions and encourages leaders to monitor this indicator.",
                    "Decathlon favours working with suppliers who respect environmental criteria.",
                    "Decathlon is sharing a FAQ of best practices to help employees develop their skills.",
                    "Decathlon is providing a tool to help you find out about your digital footprint.",
                    "I make inventory of my digital equipment and that of my team and I only keep what is essential.",
                    "I give to my equipment a second life when I'm no longer using it (reuse, repair or recycling).",
                    "I'm reducing the number of screens and their sizes because they are very polluting (TV, smartphones, tablets, connected objects).",
                    "I protect my digital equipment and prefer refurbished equipment. I keep it in good working order for as long as possible.",
                    "Google meet : I prefer the meeting together rather than using video, or I cut my camera off if on a video are more than 5 people.",
                    "Google drive : Use document sharing folders instead of personal / individual drive.",
                    "Google mail : Reduce the number of emails sent. Make sure recepients are the most relevant. Choose collaborative tools whenever it's possible.",
                    "Google drive : I only store what I need and I set up clean-up rules.",
                    "I reduce my use of the internet via 4G and prefer a wifi connection whenever possible.",
                    "I lower the resolution when I watch videos (Google Meet, Youtube, etc.).",
                    "I only use AI if the need is of real value compared with other possibilities (Google Search, internal docs, etc.).",
                    "I have a reasonable amount of disconnection time each day, integrated into my daily schedule."
                ],
                category1: "Decathlon's Digital Responsibility & Approach",
                category2: "I'm reducing the hardware amount and extend the lifespan",
                category3: "I optimize the use of the Google Suite",
                category4: "Improving my digital footprint"
            }
        },
        {
            component: <ItStep11/>,
            baseHints: [],
            description: {
                endMessage1: "You can be proud of yourselves. You have developed a coherent action plan to move towards a more responsible digital world!",
                endMessage2: <>To discover your score, you must first complete the final quiz! Be careful, penalties/bonuses can be earned in this quiz. <strong>Do it quickly!</strong></>,
                questions: [
                    {
                        name: "What percentage of greenhouse gas emissions does digital technology produce today?",
                        answers: [
                            "A - 0 to 2%",
                            "B - 3 to 4%",
                            "C - 5 to 6%",
                            "D - 7 to 8%"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Digital technology currently accounts for 3 to 4% of global greenhouse gas emissions (GHGs). Although this share remains modest compared to other sectors, the annual growth of digital consumption (data volume, terminals, etc.) should make us question.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "In terms of usage, digital overconsumption is mainly caused by:",
                        answers: [
                            "A - Sending multiple emails",
                            "B - Intensive use of videos",
                            "C - Web browsing with multiple tabs",
                            "D - Social media"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Video is the heavyweight of digital use (VOD, pornography, Tubes, networks, telemonitoring, telemedicine, etc.). It should not be forgotten that online video is not a dematerialized use.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What percentage of waste is collected and recycled globally?",
                        answers: [
                            "A - 17%",
                            "B - 37%",
                            "C - 57%",
                            "D - 77%"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "In 2019, only 17% of global waste electrical and electronic equipment (WEEE) was collected and recycled. Even if the waste is collected correctly, the majority of an electronic device is not infinitely recyclable or even recyclable at all (too small quantity, alloy, plastics).",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "Which stage has the most detrimental impact on our planet in digital technology?",
                        answers: [
                            "A - Equipment manufacturing",
                            "B - Equipment distribution",
                            "C - Equipment use",
                            "D - End of life"
                        ],
                        validAnswers: [
                            0
                        ],
                        description: "The manufacturing phase is the main source of impact of digital technology (user terminals, networks, and data centers). In addition, the manufacturing of individual equipment: televisions, computers, smartphones, tablets, etc., more numerous and rapidly obsolete than infrastructure - is the main harmful impact on the environment (60 to 90% according to the indicator).",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "On average, how many digital devices do we have per person?",
                        answers: [
                            "A - 2",
                            "B - 4",
                            "C - 6",
                            "D - 8"
                        ],
                        validAnswers: [
                            3
                        ],
                        description: "In the world, digital technology accounts for 34 billion devices for 4.1 billion users, or 8 devices per person.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "How often, on average, do we replace our smartphones today?",
                        answers: [
                            "A - 0 - 1 year",
                            "B - 2 - 3 years",
                            "C - 4 - 5 years",
                            "D - + 6 years"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Today, we replace our smartphones every 2 to 3 years on average. While they usually still work well. Fashion effect, obsolescence, lack of maintenance, etc. encourage us to too frequent renewal which is not without consequence on the environment.\nBy using your smartphone for as long as possible, you avoid the production of new devices and preserve the environment and populations!",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What is psychological obsolescence?",
                        answers: [
                            "A - A consumer replaces the casing of his broken phone",
                            "B - A consumer buys a new equipment because he needs it",
                            "C - A consumer changes his product because it seems too old, outdated",
                            "D - A consumer acts 'eco' by buying recycled"
                        ],
                        validAnswers: [
                            2
                        ],
                        description: "The idea is to encourage quick and more consumption through well-established commercial strategies (advertising, overproduction...), thus making old products 'obsolete'.\nPsychological obsolescence, also called cultural obsolescence, is the act of renewing products more quickly than necessary to be at the forefront of the trend.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "How many different materials are there in a smartphone?",
                        answers: [
                            "A - 50",
                            "B - 70",
                            "C - 200",
                            "D - 600"
                        ],
                        validAnswers: [
                            1
                        ],
                        description: "Over 70 different materials are used to manufacture a smartphone: plastic, glass but also about fifty precious and rare metals (gold, silver, platinum, tantalum, etc.).\nThese metals are not infinitely recyclable and are in limited stock.",
                        multiple: false,
                        bonus: 30
                    },
                    {
                        name: "What eco-friendly actions can be taken to have a strong impact and reduce one's environmental footprint?",
                        answers: [
                            "A - Keep your phone for a few more years to avoid producing again",
                            "B - Buy refurbished devices to avoid overproduction",
                            "C - Store your data on the cloud rather than on a hard drive",
                            "D - Question your needs to avoid overconsumption"
                        ],
                        validAnswers: [
                            0,
                            1,
                            3
                        ],
                        description: "The environmental impact of digital equipment is mainly due to the production of devices, the behavior of users significantly influences the reduction of greenhouse gas emissions caused by digital technology.\nFinally, storing data on the cloud is very energy-intensive, it is preferable to keep it on a personal hard drive.",
                        multiple: true,
                        bonus: 30
                    }
                ]
            }
        },
        {
            component: <ItStep12/>,
            baseHints: [],
            description: {
                endMessage1: "You can be proud of yourselves. You have developed a coherent action plan to move towards a more responsible digital world!"
            }
        }
    ],
}

export default sustainableItEnDecathlonScenario;
